// This is sample and deprecated.
// Please replace with your Firebase Config
export default {
  apiKey: "AIzaSyAuZTMsAo2puOC2vwlpGELNPXeld3KSxdo",
  authDomain: "wombattechnologywebsite.firebaseapp.com",
  databaseURL: "https://wombattechnologywebsite.firebaseio.com",
  projectId: "wombattechnologywebsite",
  storageBucket: "wombattechnologywebsite.appspot.com",
  messagingSenderId: "815744548852",
  appId: "1:815744548852:web:498576b2dc6212c1b983a1",
  measurementId: "G-JTSVZNX6SH"
};